'use strict';

angular.module('risevision.common.header', [
    'ui.router',
    'risevision.common.gapi',
    'risevision.common.config',
    'risevision.common.header.directives',
    'risevision.core.countries',
    'risevision.store.authorization',
    'risevision.store.services',
    'risevision.common.components.userstate',
    'risevision.common.components.loading',
    'risevision.common.components.scrolling-list',
    'risevision.common.components.logging',
    'risevision.common.support'
  ])

  .run(['analyticsFactory', 'analyticsEvents', 'environment',
    function (analyticsFactory, analyticsEvents, environment) {
      analyticsEvents.initialize();
      analyticsFactory.load(environment.TAG_MANAGER_CONTAINER_ID, environment.TAG_MANAGER_AUTH, environment.TAG_MANAGER_ENV);
    }
  ])

  .run(['$document',
    function ($document) {
      $document.on('keydown', function (event) {
        var doPrevent = false;
        if (event.keyCode === 8) {
          var d = event.srcElement || event.target;
          if ((d.tagName.toUpperCase() === 'INPUT' &&
              (
                d.type.toUpperCase() === 'TEXT' ||
                d.type.toUpperCase() === 'PASSWORD' ||
                d.type.toUpperCase() === 'FILE' ||
                d.type.toUpperCase() === 'SEARCH' ||
                d.type.toUpperCase() === 'EMAIL' ||
                d.type.toUpperCase() === 'NUMBER' ||
                d.type.toUpperCase() === 'DATE' ||
                d.type.toUpperCase() === 'TEL' ||
                d.type.toUpperCase() === 'URL')
            ) ||
            d.tagName.toUpperCase() === 'TEXTAREA') {
            doPrevent = d.readOnly || d.disabled;
          } else {
            doPrevent = true;
          }
        }
        if (doPrevent) {
          event.preventDefault();
        }
      });
    }
  ]);

angular.module('risevision.common.header.directives', []);
angular.module('risevision.store.services', []);
